.nav-wrapper{

    .navlink-wrapper{

        ul{

            li{

                .active{
                    background-color: #fff;
                    color: #442B7E;
                    font-weight: 700;
                    

                    svg{

                        path{
                            fill: #442B7E !important;
                            stroke: #442B7E !important;
                        }
                    }

                    
                }

                
            }
        }
    }
}

.nav-wrapper .navlink-wrapper ul li .active .orderIcon path:nth-child(1),.nav-wrapper .navlink-wrapper ul li .active .orderIcon path:nth-child(2){

    stroke: #442B7E !important;
    fill: #442B7E !important;

}
.nav-wrapper .navlink-wrapper ul li .active .orderIcon path:nth-child(4){
    stroke: #442B7E !important;
    fill: transparent !important;
}
.nav-wrapper .navlink-wrapper ul li .active .offerIcon path,.nav-wrapper .navlink-wrapper ul li .active .paymentIcon path,.nav-wrapper .navlink-wrapper ul li .active .reportIcon path, .nav-wrapper .navlink-wrapper ul li .active .rateIcon path, .nav-wrapper .navlink-wrapper ul li .active .dp path {
    fill: #fff !important;
}
