.order-note-text{
    &::-webkit-scrollbar{
        display: none;
    }
}

.order-details-item-list{

    li{
        position: relative;

        &::after{
            content: "";
            position: absolute;
            top: 50%;
            transform: translate(-50%,-50%);
            left: -9px;
            width: 10px;
            height: 10px;
            background-color: #5c5c5c;
            border: 3px solid #d3d3da;
            border-radius: 50%;
        }

        .adson-title,.extras-title{
            color: #B5B5C3;
            font-weight: 500;
            font-size: 14px;
        }
        .adson-desc{
            color: #333;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .extras-list{
        position: relative;

        &::after{
            content: "";
            position: absolute;
            top: 11px;
            // transform: translate(-50%,-50%);
            left: -9px;
            width: 10px;
            height: 10px;
            background-color: #5c5c5c;
            border: 3px solid #d3d3da;
            border-radius: 50%;
        }
    }
}

.order-details-item-list-ar{

    li{
        position: relative;

        &::after{
            content: "";
            position: absolute;
            top: 50%;
            transform: translate(-50%,-50%);
            right: -16px;
            width: 10px;
            height: 10px;
            background-color: #5c5c5c;
            border: 3px solid #d3d3da;
            border-radius: 50%;
        }

        .adson-title,.extras-title{
            color: #B5B5C3;
            font-weight: 500;
            font-size: 14px;
        }
        .adson-desc{
            color: #333;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .extras-list{
        position: relative;

        &::after{
            content: "";
            position: absolute;
            top: 11px;
            // transform: translate(-50%,-50%);
            left: -9px;
            width: 10px;
            height: 10px;
            background-color: #5c5c5c;
            border: 3px solid #d3d3da;
            border-radius: 50%;
        }
    }
}