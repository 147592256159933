*{
    font-family: 'Poppins', sans-serif;
    /* font-family: 'Ubuntu', sans-serif; */
}
button{
    font-family: "Playfair Display", serif !important;
}
.navbar-collapse::-webkit-scrollbar,.offers-desc-cont::-webkit-scrollbar,.menu-item-description::-webkit-scrollbar{
    display: none;
}

.terms-list-wrapper::-webkit-scrollbar,.category-container::-webkit-scrollbar{
    width: 5px;
}
.category-container::-webkit-scrollbar{
    height: 5px;
}
.terms-list-wrapper::-webkit-scrollbar-thumb,.category-container::-webkit-scrollbar-thumb{
    background-color: #442B7E;
    border-radius: 6px;
}
.terms-list-wrapper::-webkit-scrollbar-track,.category-container::-webkit-scrollbar-track{
    background-color: rgb(171, 171, 171);
    border-radius: 6px;
}
.apexcharts-legend.apexcharts-align-right .apexcharts-legend-series, .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series{
    display: flex !important;
    align-items: center;
}
.privacy-sub-list{
    list-style: upper-roman !important;
}
/* .datatable-wrapper .hkULGy, .datatable-wrapper .svYMe{
    display: none !important;
} */
.date-button-wrapper .button-range{
    border: 1px solid;
}

/* form steps progress style */

.step-progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    overflow-x: auto;
}

.step-container{
    position: relative;
    overflow: hidden;
}
.step {
    width: 30px !important;
    height: 30px;
    background-color: #ddd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
}
.step-container::after{
    content: "";
    position: absolute;
    width: calc(100% - 28px);
    height: 3px;
    background-color: #442b7e;
    left: 8.5%;
    top: 50%;
    transform: translateY(-50%);
}
.step:last-child:after{
    content: "";
    position: absolute;
    width: 0px;
    height: 3px;
    background-color: #442B7E;
    left: 100%;
}

.step.active {
    background-color: #442B7E;
    color: white;
}

.top-selling-items-wrapper::-webkit-scrollbar{
    width: 8px;
    border-radius: 10px;
    background-color: #ddd;
}
.top-selling-items-wrapper::-webkit-scrollbar-thumb{
    background-color: rgba(68, 43, 126, 0.665);
    border-radius: 10px;
}

.swal2-container{
    z-index: 99999999999999;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #ddd !important;
}


span.__rdt_custom_sort_icon__.asc svg{
    transform: rotate(0) !important;
}
