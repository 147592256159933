/* Order Summary  */
.order-summary{

    .manage-order{

        .left-section{
            .left-sec-title{
                position: relative;
            
                &::after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -20px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #C6345C;
                }
            }

            .left-sec-title-ar{
                position: relative;
            
                &::after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -20px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #C6345C;
                }
            }
        }
        

        .right-section{

            .orders-notify::-webkit-scrollbar{
                display: none;
            }
        }
    }
    
}

/* order notification component */
.order-notification-wrapper{

    &:hover{
        background-color: rgba(244, 252, 247, 0.819);
    }

    &::before{
        content: "";
        position: absolute;
        top: 20%;
        transform: translateY(-20%);
        left: -15px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #C6345C;
    }
}
